import React from "react";
import NumberFormat from "react-number-format";
import { convertToFloat } from "../../../../utils/convert-number";
import { formatNumberWithDollar } from "../../../../utils/numberFormater";
import { isNotEmptyObject } from "../../../../utils/object-util";
import { titleCase } from "../../../../utils/text-format";
import AddPropertyButton from "../../../../features/property/add-property-button";

const generateProperty = (
  accountId,
  name,
  value,
  addressDetails,
  index,
  loanListData,
  propertyEstimateValue
) => {
  return (
    <div className="linked-property-wrapper font-size-xs" key={accountId}>
      <div className="linked-property-item-value">
        <div>{name}</div>
        {value !== 0 && (
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        )}
      </div>
      {index + 1 === loanListData.length && isNotEmptyObject(addressDetails) && (
        <div className="linked-property-item-value text-right">
          <div>Property Estimate</div>
          <div>{formatNumberWithDollar(propertyEstimateValue)}</div>
        </div>
      )}
    </div>
  );
};
const generateHomeLoan = (accountId, name, value, logo) => {
  return (
    <>
      <div className="linked-account-wrapper" key={accountId}>
        <div className="la-logo">
          {logo && <img src={logo} alt="bank-logo" />}
        </div>
        <div className="linked-account-item-value">
          <div>{name}</div>
          {value !== 0 && (
            <NumberFormat
              value={value}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          )}
        </div>
      </div>
      <AddPropertyButton accountId={accountId} title="Link Property" />
    </>
  );
};

const PropertyComponent = ({
  addressDetails = {},
  propertyEstimateValue = 0,
  loanListData = [],
}) => {
  const totalHomeLoan = loanListData.reduce((init, homeLoan) => {
    const { balance = 0 } = homeLoan;
    return init + convertToFloat(balance);
  }, 0);
  const {
    locality = "",
    postcode = "",
    state = "",
    street = "",
    streetNumber = "",
    unitNumber = "",
    streetNumberHigh = "",
  } = addressDetails;
  const address = `${unitNumber ? `${unitNumber} / ` : ""}${streetNumber}${
    streetNumberHigh ? ` - ${streetNumberHigh}` : ""
  } ${street} Street,`;
  const suburb = `${titleCase(locality)}, ${state}, ${postcode}`;
  return (
    <div className="property-item-container">
      <div className="property-account-container">
        <div className="property-item-wrapper">
          {isNotEmptyObject(addressDetails) && (
            <div className="linked-property-wrapper ">
              <div className="">
                <p className="font-weight-bold text-understroke">
                  {titleCase(address)}
                </p>
                {suburb}
              </div>
              {loanListData.length > 0 ? (
                <div>
                  <span className="font-weight-bold">
                    {formatNumberWithDollar(
                      totalHomeLoan + propertyEstimateValue
                    )}
                  </span>{" "}
                  Equity
                </div>
              ) : (
                <div className="text-right">
                  <div>Property Estimate</div>
                  <div>{formatNumberWithDollar(propertyEstimateValue)}</div>
                </div>
              )}
            </div>
          )}

          {loanListData.length > 0 &&
            loanListData.map((item, index) => {
              const {
                institutionLogo: logo = "",
                name = "",
                balance: value = 0,
                accountId = "",
              } = item;

              return isNotEmptyObject(addressDetails)
                ? generateProperty(
                    accountId,
                    name,
                    value,
                    addressDetails,
                    index,
                    loanListData,
                    propertyEstimateValue
                  )
                : generateHomeLoan(accountId, name, value, logo);
            })}
        </div>
      </div>
    </div>
  );
};

export default PropertyComponent;
