import React from "react";
import PropTypes from "prop-types";
import ScrollContainer from "react-indiana-drag-scroll";
import PropertyComponent from "./property-component";
import AddPropertyButton from "../../../../features/property/add-property-button";

const LinkedPropertyWrap = (props) => {
  const {
    addressDetails = {},
    propertyId = "",
    estimate = 0,
    homeloan = [],
    key,
  } = props;
  return (
    <PropertyComponent
      key={key}
      addressDetails={addressDetails}
      propertyId={propertyId}
      loanListData={homeloan}
      propertyEstimateValue={estimate}
    />
  );
};

const renderHomeLoanList = (propertyList = [], homeloan = []) => {
  const linkedHomeLoan = propertyList.reduce((list, property) => {
    const { accountIds = "" } = property;
    return list + accountIds;
  }, "");

  return homeloan
    .filter((homeLoanItem) => {
      const { accountId = "" } = homeLoanItem;
      return linkedHomeLoan.indexOf(accountId) < 0;
    })
    .map((item) => (
      <LinkedPropertyWrap key={item.accountId} homeloan={[{ ...item }]} />
    ));
};

const renderPropertyList = (items = [], homeloan = [], type = "") =>
  items.map((item) => {
    const { propertyId = "", accountIds = "" } = item;
    const propertyDebt = homeloan.filter((homeloanItem) => {
      const { accountId = "" } = homeloanItem;
      return accountIds?.indexOf(accountId) >= 0;
    });
    if (type !== "homeLoan") {
      return (
        <LinkedPropertyWrap
          key={propertyId}
          {...item}
          homeloan={propertyDebt}
        />
      );
    }
    if (propertyDebt.length) {
      return (
        <LinkedPropertyWrap
          key={propertyId}
          {...item}
          homeloan={propertyDebt}
        />
      );
    }
    return <></>;
  });

const PropertyListCarousel = ({
  propertyList = [],
  accountList = [],
  type = "",
}) => {
  return (
    <ScrollContainer horizontal className="scroll-container">
      <div className="linked-bank linked-account-section">
        <div className="d-flex">
          {type === "homeLoan" &&
            renderHomeLoanList(propertyList, accountList, type)}
          {renderPropertyList(propertyList, accountList, type)}
        </div>
      </div>
      {type === "property" && <AddPropertyButton />}
    </ScrollContainer>
  );
};

PropertyListCarousel.propTypes = {
  propertyList: PropTypes.array,
  accountList: PropTypes.array,
  type: PropTypes.number,
};

export default PropertyListCarousel;
